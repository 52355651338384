<template>
  <div>
    <v-card class="ma-2">
      <div class="d-flex justify-space-between px-4">
        <v-card-title>Transactions report</v-card-title>
        <v-btn class=" my-auto primary" @click="getTransactionsExcel()"
        :disabled="isLoading">
          Download Excel
          <v-icon v-if="isLoading" class="ml-2">mdi-loading mdi-spin</v-icon>
          <v-icon v-else
              right
              dark
          >
            mdi-cloud-download
          </v-icon>
        </v-btn>
      </div>
      <!-- Filter -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <h4>Report filter</h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12" sm="2">
                <v-autocomplete
                    v-model="transactionFilter.clientId"
                    :items="clientsLogin"
                    item-text="login"
                    item-value="id"
                    label="Client"
                    @change="getTransactions(1)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Parent ID"
                    outlined
                    v-model="transactionFilter.parentPartner"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field
                    label="Branch ID"
                    v-model="transactionFilter.branchId"
                    outlined
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Card ID"
                    outlined
                    v-model="transactionFilter.cardId"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Card Number"
                    outlined
                    v-model="transactionFilter.cardNumber"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Phone Number"
                    outlined
                    v-model="transactionFilter.phoneNumber"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Transaction ID"
                    outlined
                    v-model="transactionFilter.transactionId"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="User ID"
                    outlined
                    v-model="transactionFilter.userId"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Merchant ID"
                    outlined
                    v-model="transactionFilter.merchantId"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="Client Extra ID"
                    outlined
                    v-model="transactionFilter.clientExtraId"
                    @keyup.enter="getTransactions(1)"

                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field
                    label="utrNo"
                    outlined
                    v-model="transactionFilter.utrNo"
                    @keyup.enter="getTransactions(1)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="2">
                <v-select
                    v-model="transactionFilter.cardEmitter"
                    :items="cardEmitters"
                    label="Card Emitter"
                    item-text="name"
                    item-value="id"
                    @change="getTransactions(1)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    v-model="transactionFilter.type"
                    :items="transactionTypes"
                    label="Transaction type"
                    item-text="name"
                    item-value="id"
                    @change="getTransactions"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    v-model="transactionFilter.status"
                    :items="statuses"
                    label="Status"
                    item-text="name"
                    item-value="id"
                    @change="getTransactions(1)"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="2">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select date range"
                        prepend-icon="mdi-calendar"
                        readonly
                        clearable
                        @click:clear="transactionFilter.endDate = null, transactionFilter.startDate = null, getTransactions(1)"
                        v-bind="attrs"
                        v-on="on"

                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="saveDate(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-btn
                @click="getTransactions(1)"
                color="primary"
            >Search
            </v-btn>

            <v-btn
                v-if="transactionFilter.clientId == 2 && transactionFilter.status == 6"
                class="ml-5"
                @click="checkAndReverseAuctionTransactions()"
                color="secondary"
            >Check and Reverse
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <v-card class="ma-2 pa-4">
      <v-card-text>
        <v-data-table
            :loading="isLoading"
            :loading-text="'Loading...'"
            :headers="headers"
            :items="transactionsList"
            :items-per-page="transactionFilter.count"
            hide-default-footer
        >
          <template v-slot:item.clientLogin="{item}">
            <div>{{ item.clientLogin }} <span class="font-italic" v-if="item.clientParent">({{
                item.clientParent
              }})</span></div>
          </template>
          <template v-slot:item.amount="{item}">
            <div>{{ item.amount | toCurrency }}</div>
          </template>
          <template v-slot:item.createdDate="{item}">
            <div>{{ item.createdDate | dateTimeFormat }}</div>
          </template>
          <template v-slot:item.finishedDate="{item}">
            <div>{{ item.finishedDate | dateTimeFormat }}</div>
          </template>
          <template v-slot:item.statusComment="{item}">
            <div>
              <v-btn
                  x-small
                  dark
                  :color="setStatusColor(item.status)"
              >
                {{ item.statusComment }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.typeComment="{item}">
            <div>
              <v-btn
                  x-small
                  dark
                  :color="setTypeColor(item.type)"
              >
                {{ item.typeComment }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.actions="{item}">
            <div class="d-flex justify-space-between">
              <v-btn @click="openTransaction(item.id)" icon color="info" small>
                <v-icon small> mdi-eye</v-icon>
              </v-btn>

              <v-btn @click="checkTransaction(item.id)" icon color="info" small>
                <v-icon small> mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <div class="text-center" v-if="transactionsList.length > 0">
        <v-pagination
            v-model="transactionFilter.page"
            :length="totalCount"
            :total-visible="7"
        ></v-pagination>
      </div>
    </v-card>

    <!-- <v-dialog> -->
    <v-dialog v-model="transactionModal" max-width="600px">
      <v-card>
        <v-card-title>Transaction data</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      Field Name
                    </th>
                    <th class="text-left">
                      Value
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {{ }}
                  <tr
                      v-for="(item, i) in transactionData"
                      :key="i"
                  >
                    <template v-if="i != 'log'">
                      <td>{{ i }}</td>
                      <td>{{ item }}</td>
                    </template>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <div v-for="(pair, index) in logs" :key="index">
                      <span class="red--text">Log: №{{ index + 1 }}</span><br>
                      <span v-for="(section, sectionName) in pair" :key="sectionName">
                        {{ sectionName }}:<pre>{{ section }}</pre>
                      </span>
                      <hr/>
                    </div>
                  </tbody>
                </template>
              </v-simple-table>
            </v-row>
            <v-row>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="reversePopup = true" style="width: 100%" color="error"
                       v-if="transactionData.isCanCancel">
                  Reverse
                </v-btn>
              </v-col>
              <v-col>
                <v-btn @click="transactionModal = false" style="width: 100%" color="success">
                  Close
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- </v-dialog> -->
    <div class="text-center">
      <v-dialog
          v-model="reversePopup"
          width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Reverse transaction
          </v-card-title>

          <v-card-text>
            Are you sure?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="reversePopup = false"
            >
              Cancel
            </v-btn>
            <v-btn
                color="success"
                text
                @click="reverseTransaction(transactionData.id)"
            >
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>
</template>

<script>
export default {
  name: 'ReportPage',
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: true},
        {text: 'Login', value: 'clientLogin', align: 'center', sortable: true},
        {text: 'utrNo', value: 'utrNo', align: 'center', sortable: false},
        {text: 'amount', value: 'amount', align: 'center', sortable: false},
        {text: 'Created Date', value: 'createdDate', align: 'center', sortable: true},
        {text: 'Finished Date', value: 'finishedDate', align: 'center', sortable: true},
        {text: 'clientExtraId', value: 'clientExtraId', align: 'center', sortable: false},
        {text: 'Status', value: 'statusComment', align: 'center', sortable: true},
        {text: 'cardNumber', value: 'cardNumber', align: 'center', sortable: true},
        {text: 'cardEmitter', value: 'cardEmitter', align: 'center', sortable: true},
        {text: 'Type', value: 'typeComment', align: 'center', sortable: true},
        {text: 'Info', value: 'actions', align: 'center', sortable: true},
      ],
      transactionFilter: {
        count: 20,
        page: 1,
        merchantId: null,
        clientId: null,
        branchId: null,
        cardNumber: null,
        phoneNumber: null,
        transactionId: null,
        utrNo: null,
        cardId: null,
        clientExtraId: null,
        cardEmitter: null,
        type: null,
        startDate: null,
        endDate: null,
        status: null,
        parentPartner: null,
        userId: null
      },
      cardEmitters: [
        {id: null, name: "All"},
        {id: 0, name: "UZCARD"},
        {id: 1, name: "HUMO"},
      ],
      transactionTypes: [
        {id: null, name: "All"},
        {id: 0, name: "Payment"},
        {id: 1, name: "P2P"},
        {id: 3, name: "Auto Payment"},
        {id: 2, name: "Partial reverse"},
      ],
      statuses: [
        {id: null, name: "All"},
        {id: 0, name: "Created"},
        {id: 1, name: "Successful"}, // green
        {id: 2, name: "Error"},
        {id: 3, name: "Error in PC"},
        {id: 4, name: "Reversed"}, // red
        {id: 5, name: "Error in MUNIS"},
        {id: 6, name: "Need to check"},
        {id: 7, name: "Hold"},
        {id: 8, name: "Cancelled"}, // red
        {id: 9, name: "Need to cancel"},
        {id: 10, name: "Need to check manually"},
        {id: 11, name: "Admin reverse"}, // red
      ],
      dates: null,
      totalCount: 1,
      transactionsList: [],
      transactionModal: false,
      update: false,
      isLoading: false,
      panel: 0,
      date: [],
      menu: false,
      modal: false,
      menu2: false,
      transactionData: [],
      clientsLogin: [],
      reversePopup: false,
      logs: []
    }
  },
  methods: {
    async getTransactions(page) {
      if (page == 1) {
        this.transactionFilter.page = 1;
      }
      try {
        this.isLoading = true;
        this.transactionsList = []
        const res = await this.$http.post('Report/getTransactions', this.transactionFilter);
        if (!res.error) {
          this.transactionsList = res.result.items
          this.totalCount = parseInt(res.result.allCount / this.transactionFilter.count)
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    async getClientsLogin() {
      try {
        const res = await this.$http.get('Client/getClientsLogin')
        this.clientsLogin = res.result.items
        this.clientsLogin.unshift({id: null, login: "All"})
      } catch (e) {
        this.errorNotification(e.response.data.error.message)
      }
    },
    async checkAndReverseAuctionTransactions() {
      try {
        this.isLoading = true;
        const res = await this.$http.get('Report/checkAndReverseAuctionTransactions');
        if (!res.error) {
          this.transactionsList = res.result.items
          this.totalCount = parseInt(res.result.allCount / this.transactionFilter.count)
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },

    saveDate(date) {
      this.$refs.menu.save(date);
      this.transactionFilter.startDate = date[0];
      this.transactionFilter.endDate = date[1];
      this.getTransactions();
    },
    async getTransactionsExcel() {
      try {
        this.isLoading = true;
        const res = await this.$http.post('Report/getTransactionsExcel', this.transactionFilter);
        if (!res.error) {
          console.log(res.result.base64String)
          this.downloadBlobFile(this.base64toBlob(res.result.base64String), `excelReport.xlsx`);
        }
      } catch (e) {
        this.errorNotification(e.response.data.error.errorMessage)
      } finally {
        this.isLoading = false
      }
    },
    openTransaction: async function (id) {
      this.transactionData = []
      try {
        this.isLoading = true;
        const res = await this.$http.get(`Report/getTransaction?transactionId=${id}`);
        if (!res.error) {
          this.transactionData = res.result.item;
          this.transactionModal = true;
          this.logs = [];
          if (res.result.item.log) {
            this.parseDataString(res.result.item.log)
          }
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    parseDataString(dataString) {
      const sections = dataString.split(/\n+/);
      const logs = [];
      let currentLog = null;

      for (const line of sections) {
        try {
          const matches = line.match(/^(.*?):\s*(\{.*\})/);

          if (matches && matches.length === 3) {
            const sectionName = matches[1].toLowerCase();
            const jsonData = matches[2];

            if (
                sectionName === "checkrequest" ||
                sectionName === "performrequest" ||
                sectionName === "request"
            ) {
              // Create a new log object for "checkRequest", "performRequest", or "request"
              currentLog = {[sectionName]: JSON.parse(jsonData)};
            } else if (
                sectionName === "checkresponse" ||
                sectionName === "performresponse" ||
                sectionName === "response"
            ) {
              // Add "checkResponse", "performResponse", or "response" to the current log object
              currentLog[sectionName] = JSON.parse(jsonData);
              logs.push(currentLog);
              currentLog = null; // Reset the current log
            }
          } else if (currentLog && line.trim() !== "") {
            // Append lines to the current section if not empty
            const sectionKey = Object.keys(currentLog)[0];
            currentLog[sectionKey] += `\n${line}`;
          }
        } catch (error) {
          console.error("Error parsing JSON: ", error);
        }
      }

      // Now 'logs' contains an array of objects, each representing a set of paired sections
      this.logs = logs
    },
    async checkTransaction(id) {
      try {
        this.isLoading = true;
        const res = await this.$http.get(`Report/checkTransaction?transactionId=${id}`);
        if (!res.error) {
          let _vm = this;
          const index = this.transactionsList.findIndex((item) => item.id == id);
          if (this.transactionsList[index] && this.transactionsList[index].status !== res.result.item.status) {
            this.$set(_vm.transactionsList, index, res.result.item);
            this.successNotification('Успешно');
          }
          this.infoNotification('Статус не изменился')
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    async reverseTransaction(id) {
      try {
        this.isLoading = true;
        const res = await this.$http.get(`Report/reverseTransaction?transactionId=${id}`);
        if (!res.error) {
          await this.getTransactions()
          this.reversePopup = false;
          this.transactionModal = false;
          this.successNotification('Успешно')
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.isLoading = false
      }
    },
    setTypeColor(type) {
      const types = {
        '0': 'success',
        '1': 'yellow darken-3',
        '2': 'blue-grey',
      };
      return types[type];
    },
    setStatusColor(status) {
      const statuses = {
        '0': 'yellow darken-4',
        '1': 'light-green',
        '2': 'yellow darken-4',
        '3': 'yellow darken-4',
        '4': 'red darken-4',
        '5': 'yellow darken-4',
        '6': 'yellow darken-4',
        '7': 'yellow darken-4',
        '8': 'red darken-4',
        '9': 'yellow darken-4',
        '10': 'yellow darken-4',
        '11': 'red darken-4',
      };

      // {id: null, name: "All"},
      // {id: 0, name: "Created"},
      // {id: 1, name: "Successful"}, // green
      // {id: 2, name: "Error"},
      // {id: 3, name: "Error in PC"},
      // {id: 4, name: "Reversed"}, // red
      // {id: 5, name: "Error in MUNIS"},
      // {id: 6, name: "Need to check"},
      // {id: 7, name: "Hold"},
      // {id: 8, name: "Cancelled"}, // red
      // {id: 9, name: "Need to cancel"},
      // {id: 10, name: "Need to check manually"},
      // {id: 11, name: "Admin reverse"}, // red


      return statuses[status];
    }
  },
  created() {
    this.getTransactions();
    this.getClientsLogin();
    // this.$http.get(`Role/getRoles`).then((res) => {
    //   if (res && res.result && res.result.roles && res.result.roles.length > 0) {
    //     // console.log(res.result.roles);
    //     this.selectItem = res.result.roles
    //     this.roleSelect = res.result.roles[0].id
    //     // this.getList()
    //   }
    // })
    //     .catch((e) => {
    //       console.log(e)
    //     });

  },
  watch: {
    'transactionFilter.page': {
      handler: function (val, oldVal) {
        this.getTransactions();
      },
      deep: true
    },
  }
}
</script>
